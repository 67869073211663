import React, {useEffect, useState} from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import HeroImage from "../images/discovery-banner.png";
import AudienceIcon from "../assets/icons/audience-icon.svg";
import MerchandisersIcon from "../assets/icons/merchandisers-icon.svg";
import MarketingIcon from "../assets/icons/marketing-icon.svg";
import LeaderIcon from "../assets/icons/leader-icon.svg";
import SearchcontentIcon from "../assets/icons/search-content-icon.svg";
import AwarenessIcon from "../assets/icons/awareness-icon.svg";
import AlgorithmIcon from "../assets/icons/algorithm-icon.svg";
import AiSearchDiscovery from "../images/ai-search-discovery.jpg";
import CodecircleIcon from "../assets/icons/code-circle-icon.svg";
import VisualMerchandising from "../images/visual-merchandising.jpg";
import MediaManagerImage from "../images/discovery-manager-image.jpg";
import RecommendImage from "../images/Recommended-foryou.png"; 
import AbTestingIcon from "../assets/icons/ab-testing-icon.svg";
import PersonalizedRecommendations from "../images/personalized-recommendations.jpg"; 
import CalenderIcon from "../assets/icons/calender-icon.svg";
import InsightIcon from "../assets/icons/insight-icon.svg";
import TickSquareIcon from "../assets/icons/tick-square-icon.svg";
import IntegrationImage from "../images/integration-banner-image.png";
import MerchandIcon from "../assets/icons/merchandise-icon.svg";
import JourneyIcon from "../assets/icons/journey-icon.svg";
import PersonalizeIcon from "../assets/icons/personalize-icon.svg";
import CTABannerImage from "../images/journey-cta.png";
import SEOIcon from "../assets/icons/seo-icon.svg";
import CompleteIcon from "../assets/icons/complete-icon.svg";
import BookIcon from "../assets/icons/book-icon.svg";
import ChartIcon from "../assets/icons/chart-icon.svg";
import TextIcon from "../assets/icons/text-icon.svg";
import SKUsearchIcon from "../assets/icons/sku-search-icon.svg";
import MerchandisingSearchIcon from "../assets/icons/merchandising-search-icon.svg";
import BoostIcon from "../assets/icons/boost-icon.svg";
import EventIcon from "../assets/icons/event-icon.svg";
import BuildIcon from "../assets/icons/build-icon.svg";
import DocumentIcon from "../assets/icons/document-icon.svg";
import ABTestingSolidIcon from "../assets/icons/ab-testing-solid-icon.svg";
import TargetIcon from "../assets/icons/target-icon.svg";
import MerchandisingEditIcon from "../assets/icons/merchandising-edit-icon.svg";
import ClockIcon from "../assets/icons/clock-icon.svg";
import UserSearchIcon from "../assets/icons/user-search-icon.svg";
import TimeIcon from "../assets/icons/time-icon.svg"; 
import FrequentlyBoughtIcon from "../assets/icons/frequently-bought-icon.svg"; 
import LikeIcon from "../assets/icons/like-icon.svg"; 
import HeartIcon from "../assets/icons/heart-icon.svg"; 
import CopyIcon from "../assets/icons/copy-icon.svg"; 
import BestIcon from "../assets/icons/best-icon.svg";  
import BuildingIcon from "../assets/icons/buildings-icon.svg";
import TechIcon from "../assets/icons/tech-icon.svg";  
import StopwatchIcon from "../assets/icons/stopwatch-icon.svg";  
import CloudConnectionIcon from "../assets/icons/cloud-connection-icon.svg";
import SimilarProductImage from "../images/similar-products-toggle-img.png";
import BestSellerImage from "../images/best-seller-toggle-img.png";
import FrequentlyBoughtTogetherImage from "../images/frequently-bought-together.png";
import YouMayAlsoLikeImage from "../images/you-may-also-like.png";



import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const DiscoveryPage = () => {
    const [itemIsActive, setItemIsActive] = useState(1);
    useEffect(() => {
        const active = itemIsActive >= 5 ? 1 : itemIsActive;
        setItemIsActive(active);
        // const timer = () => setTimeout(() => {
        //     const active = itemIsActive >= 5 ? 1 : itemIsActive + 1;
        //     setItemIsActive(active);
        // }, 7000);
        // let timerId = null;
        // timerId = timer();

        return () => {
            //clearTimeout(timerId);
        };
    }, []);

    return (
        <div className="template-cms discovery-page header-position">
            <Helmet>
                <title>Discover anything and everything on your store using Experro</title>
                <meta name="description" content="Give the liberty to your visitors of searching anything and everything that they are looking in your store using AI based advanced search."/>
            </Helmet>
            <Layout>
                <div className="hero-banner mb-208">
                    <div className="hero-banner-wrap">
                        <div className="left-block">
                            <div className="banner-text">
                                <h2 className="mb-24">Turn your storefront into a conversion machine with AI search and merchandising</h2>
                                <p>Increase add-to-cart ratio, product sales, and keep the shoppers engaged with personalized recommendations, relevant search results, and smart merchandising</p>
                                <Link className="button right-arrow" to="/contact/">Get a Demo</Link>
                            </div>
                        </div>
                        <div className="right-block">
                            <img loading="lazy" src={HeroImage} title="Omnichannel Retail Experience" alt="omnichannel retail platform" />
                        </div>
                    </div>
                </div>

                <div className="team-works-section mb-208">
                    <div className="container">
                        <h3 className="font-medium mb-80">
                            Empower your team to deliver rich, relevant, and rewarding product and content discovery experiences 
                        </h3>
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-4">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <AudienceIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Customers</h6>
                                            <p className="small-text">Deliver blazing-fast and personalized product discovery that matches the intent and taste of each customer. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MerchandisersIcon  />
                                        </div>
                                        <div className="text-block">
                                            <h6>Merchandisers</h6>
                                            <p className="small-text">Drive product visibility and sales by curating product placements using insights and visual tools.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MarketingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Marketers</h6>
                                            <p className="small-text">Grow customer engagement and sales with personalized promotions, campaigns, and brand experiences.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <LeaderIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Leaders</h6>
                                            <p className="small-text">Use search, product, and customer intelligence to create powerful eCommerce strategies and boost ROI.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-with-image d-flex align-v-center mb-104" id="ai-search">
                    <div className="left-block">
                        <div className="content-inner">
                            <h3 className="section-title">AI Search</h3>
                            <p className="mb-80">Increase check-outs by delivering lightning-fast search experiences that improve the discoverability of content and products for customers.</p>
                            <Accordion className="toggle-block" preExpanded={['a']}>
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><SearchcontentIcon /></i> Product Search
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                    <p>Offer fast and relevant product search results that are instantly ranked based on customers’ preferences and segmentation.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><SEOIcon /></i> Site Search
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Inspire users to engage and explore more with powerful site search across blogs, pages, guides, trends, and educational content. </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><AwarenessIcon /></i> Semantic Awareness
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Use natural language processing to understand the intent and offer relevant results for searches like “camera with lens” and “lens for camera.” </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><AlgorithmIcon /></i> Eliminate Null Results
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>No results, no sales. Avoid zero-result pages with enhanced search queries that check for hundreds of synonyms, spellings, broader matches, and more. </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                           
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={AiSearchDiscovery} title="AI Search" alt="AI Search" />
                    </div>
                </div>

                <div className="border-bottom mb-104 pb-104">
                    <div className="container">
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid mb-64">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CompleteIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Auto-complete</h6>
                                            <p className="small-text">Give your users a headstart with intelligent term suggestions as they start typing their search query. Remove irrelevant and null result terms in real-time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-64">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CodecircleIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Search APIs</h6>
                                            <p className="small-text">Seamlessly integrate rich content and product search capabilities to your existing eCommerce sites and mobile apps.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-64">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <ChartIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Search Insights</h6>
                                            <p className="small-text">Use actionable insights to understand what your customers are looking for - products, brands, variants - and optimize their search experiences.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <BookIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Synonyms</h6>
                                            <p className="small-text">Improve search relevancy with industry-specific synonyms libraries that are automatically enriched with search terms, user intent, and new definitions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <TextIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Spelling Corrections</h6>
                                            <p className="small-text">Auto-correct the spelling mistakes in the search queries to ensure that your customers find what they are looking for without winning a Spelling Bee.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <SKUsearchIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>SKU Lookup</h6>
                                            <p className="small-text">Empower your B2B and B2C customers to find specific parts and product variants by directly typing the SKUs in the search bar.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center-image-section mb-104" id="visual-merchandising">
                    <div className="container">
                        <div className="section-header">
                            <h3 className="section-title text-center">Visual Merchandising</h3>
                            <p className="text-center mb-64">Arrange and display products across different search and category pages to align with your marketing strategy and motivate shoppers to take action</p>
                        </div>
                        <div className="image-block mb-104">
                            <img loading="lazy" src={VisualMerchandising} title="Visual Merchandising" alt="Visual Merchandising" />
                        </div>
                        <div className="icon-with-text-block icon-with-text-flex-box">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MerchandisingSearchIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Search Merchandising</h6>
                                            <p className="small-text">Optimize product displays for each search query based on click rates, margins, stock levels, brand campaigns, and customer preferences.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <BoostIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Category Merchandising</h6>
                                            <p className="small-text">Manage product placement specific to any product category to boost sales of a particular brand, product, or product variant.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <EventIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Event Merchandising</h6>
                                            <p className="small-text">Schedule the product displays and promotional campaigns in advance for holidays and sale events such as Black Friday and Mother’s Day.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-with-image left-side-image d-flex align-v-center mb-104">
                    <div className="left-block">
                        <div className="content-inner">

                            <Accordion className="toggle-block" preExpanded={['a']}>
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><BuildIcon /></i> Visual Merchandiser
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                    <p>Optimize the search and category product displays with an intuitive visual editor to achieve your goals. Use performance insights, define business rules and customize the results for each query and segment.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><DocumentIcon /></i> Intellient rule engine
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                    <p>Use any product and brand attributes to boost or bury, include or exclude them in the result set, and pin the high converting product in the #1 spot - just with a few clicks, no customizations or dev work required.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><ABTestingSolidIcon /></i> A/B Test Product Assortments
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                    <p>Always make the right and rewarding decisions by quickly testing your hypothesis, product mixes, and merchandising strategies using A/B tests and insights.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><CalenderIcon /></i> Campaign Scheduling
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                    <p>Automate the scheduling of all merchandising campaigns to efficiently plan, launch and manage targeted promotions and sales events with ease.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={MediaManagerImage} title="Media Manager Image" alt="Media Manager Image" />
                    </div>
                </div>


                <div className="flexible-content-block border-bottom mb-104 pb-104">
                    <div className="container">
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <TargetIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Productivity Boost</h6>
                                            <p className="small-text">Merchandisers save 35% time by removing the manual and guesswork with Experro - they find new opportunities, understand the reasoning and adopt their campaigns quickly in a matter of clicks.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MerchandisingEditIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Merchandising Insights</h6>
                                            <p className="small-text">Optimize strategies and campaigns with AI-powered insights to ensure that each session and search leads to a great experience and more sales.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <ClockIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Real-time Performance</h6>
                                            <p className="small-text">Launch campaigns, rule changes, and product displays with instant effect and let Experro personalize them based on real-time user behavior.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="content-with-image d-flex align-v-center mb-208 presonalized" id="personalized-recommendations">
                    <div className="left-block">
                        <div className="content-inner">
                            <h3 className="section-title">Personalized Recommendations</h3>
                            <p className="mb-80">Use AI to delight customers with 1-on-1 experiences and increase cart value with targeted cross-sell and up-sell opportunities.</p>
                            
                            <Accordion className="toggle-block" preExpanded={['a']}>
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><TimeIcon /></i> Ready-to-use Recommendations
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Use out-of-the-box and trained AI that automatically generates recommendations at key conversion points in the shopper’s journey.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><UserSearchIcon /></i> Recommendation Builder & Simulator
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Create personalized widgets for search, category, and product pages, test the recommendations, and push them live with just a few clicks.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><DocumentIcon /></i> Warm Start for New Shopper
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Eliminate the cold start problem for the new shoppers with day-0 personalization like bestsellers, bought together, similar, and more.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><InsightIcon /></i> Real-time Personalization
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Use real-time customer behaviors such as search queries, cart status, and browsing patterns to offer ideal products at the right time.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                           
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={PersonalizedRecommendations} title="Content Ops" alt="Content Ops" />
                    </div>
                </div>

                <div className="cta-banner mb-208">
                    <div className="container">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-end">
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CTABannerImage} title="CTA Banner Image" alt="CTA Banner Image" />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-block pt-64 pb-64">
                                        <h4>See how Experro can empower your business to transform eCommerce.</h4>
                                        <p className="mb-40">We're helping B2B, B2C, D2C, and retailers to reinvent their digital experiences for a customer-first future.</p>
                                        <Link className="button black-button" to="/contact/">Get a Demo</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-with-image left-side-image d-flex align-v-center mb-104 image-tab-section" id="inspire-customers-block">
                    <div className="left-block">
                        <div className="content-inner">
                            <h3 className="mb-80 font-medium">Inspire customers with powerful recommendations</h3>
                            <div className="toggle-block">
                                <div className={"toggle-item"  + (itemIsActive === 1 ? " active" : "")} onClick={() => { setItemIsActive(1); }}>
                                    <h6 className="toggle-title"><i className="icon"><FrequentlyBoughtIcon /></i> Frequently Bought Together</h6>
                                </div>
                                <div className={"toggle-item"  + (itemIsActive === 2 ? " active" : "")} onClick={() => { setItemIsActive(2); }}>
                                    <h6 className="toggle-title"><i className="icon"><LikeIcon /></i> Recommended for you</h6>
                                </div>
                                <div className={"toggle-item"  + (itemIsActive === 3 ? " active" : "")} onClick={() => { setItemIsActive(3); }}>
                                    <h6 className="toggle-title"><i className="icon"><HeartIcon /></i> You may also like</h6>
                                </div>
                                <div className={"toggle-item"  + (itemIsActive === 4 ? " active" : "")} onClick={() => { setItemIsActive(4); }}>
                                    <h6 className="toggle-title"><i className="icon"><CopyIcon /></i> Similar products</h6> 
                                </div>
                                <div className={"toggle-item"  + (itemIsActive === 5 ? " active" : "")} onClick={() => { setItemIsActive(5); }}>
                                    <h6 className="toggle-title"><i className="icon"><BestIcon /></i> Best Sellers</h6> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-block">
                        <div className={"image-block item-1" + (itemIsActive === 1 ? " active" : "")}>
                            <img loading="lazy" src={FrequentlyBoughtTogetherImage} title="Frequently Bought Together" alt="Frequently Bought Together" />
                        </div>
                        <div className={"image-block item-2" + (itemIsActive === 2 ? " active" : "")}>
                            <img loading="lazy" src={RecommendImage} title="Recommended for you" alt="Recommended for you" />
                        </div>
                        <div className={"image-block item-3" + (itemIsActive === 3 ? " active" : "")}>
                            <img loading="lazy" src={YouMayAlsoLikeImage} title="You may also like" alt="You may also like" />
                        </div>
                        <div className={"image-block item-4" + (itemIsActive === 4 ? " active" : "")}>
                            <img loading="lazy" src={SimilarProductImage} title="Similar products" alt="Similar products" />
                        </div>
                        <div className={"image-block item-5" + (itemIsActive === 5 ? " active" : "")}>
                            <img loading="lazy" src={BestSellerImage} title="Best Sellers" alt="Best Sellers" />
                        </div>
                    </div>
                </div>


                <div className="flexible-content-block border-bottom mb-104 pb-104">
                    <div className="container">
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <TechIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Self-learning Engine</h6>
                                            <p className="small-text">Reduce time to market, costs, and effort required to build and offer personalization at scale. Use drag and drop widgets and UI to enable new experiences in minutes without IT dependencies.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CodecircleIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Blazing-fast APIs</h6>
                                            <p className="small-text">Experro offers blazing-fast REST APIs and libraries to integrate recommendations into your existing site, apps, or third-party systems.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <AbTestingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>A/B Testing</h6>
                                            <p className="small-text">Build, launch and test multiple personalization and engagement strategies with a built-in A/B testing framework and insights.	</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="integrates-section text-center mb-104 pb-104 border-bottom">
                    <div className="container">
                        <div className="title-block mb-32">
                            <h3 className="mb-32">Integrates with eCommerce  platforms <br /> and your existing apps</h3>
                            <Link className="btn-link right-arrow" to="/integration/">See Integrations</Link>
                        </div>
                        <div className="image-block">
                            <img loading="lazy" src={IntegrationImage} title="Integration Banner" alt="Integration Banner" />
                        </div>
                    </div>
                </div>

                <div className="flexible-content-block border-bottom mb-104 pb-104">
                    <div className="container">
                        <h3 className="mb-80 font-medium">Discovery platform that is built to scale and optimized <br></br>to perform</h3>
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CloudConnectionIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Designed for Speed and Scale</h6>
                                            <p className="small-text">Optimized and simplified to manage millions of engagements and billions of experiences for multiple websites.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <BuildingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Enterprise Grade Security</h6>
                                            <p className="small-text">A unified experience platform built to offer your business with industry-leading security, scalability, and flexibility.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <StopwatchIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Get Started in Minutes</h6>
                                            <p className="small-text">Reduce time to market, costs, and effort required to build and offer personalization at scale. Use drag and drop widgets and UI to enable new experiences in minutes without IT dependencies.	</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="point-title-block mb-208">
                    <div className="container">
                        <div className="icon-with-title-block">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner d-flex">
                                        <div className="icon-block">
                                            <TickSquareIcon />
                                        </div>
                                        <div className="text-block">
                                        <p className="font-medium font-dark-color">Multi-site and multi-lingual support</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner d-flex">
                                        <div className="icon-block">
                                            <TickSquareIcon />
                                        </div>
                                        <div className="text-block">
                                        <p className="font-medium font-dark-color">Cloud & dedicated hosting options</p>
                                       </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner d-flex">
                                        <div className="icon-block">
                                            <TickSquareIcon />
                                        </div>
                                        <div className="text-block">
                                            <p className="font-medium font-dark-color">Flexible to fit your unique needs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="experro-more-details-section mb-208">
                    <div className="container">
                        <h3 className="mb-32 text-center font-medium">There is more to Experro</h3>
                        <div className="icon-with-text-block icon-with-text-border-box">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MerchandIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Headless CMS</h6>
                                            <p className="small-text">Publish faster with a CMS that is made for developers and content editors.
</p>
                                            <Link className="btn-link right-arrow" to="/cms/#headless-cms">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <PersonalizeIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Visual Site Builder</h6>
                                            <p className="small-text">Create content intuitively and collaboratively with a visual editing experience.</p>
                                            <Link className="btn-link right-arrow" to="/cms/#visual-site-builder">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <JourneyIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Marketing Automation</h6>
                                            <p className="small-text">Accelerate marketing processes by automating omnichannel engagement.
</p>
                                            <Link className="btn-link right-arrow" to="/journey/#marketing-automation">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default DiscoveryPage;